html{
    background-color: rgb(25,24,24);
    color: white; 
}

/* ****************************
Header
**************************** */
a {
    text-decoration: none;
    color: white;
}
.nav {
    display: flex;
    padding: 5px;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    background-color: rgb(29,30,32);
    color: white;


  }

  .nav__logo {
    font-size: 18px;
    color: white;
  }
  
  .nav__links {
      display: flex;
      font-size: 16px;
      align-items: center;
      color:white;
      
  }

  .nav a {
    text-transform: uppercase;
    text-decoration: none;
    padding: 8px;
    color: white;
  }

  .nav a:visited {
      color: white; 
  }

/* ****************************
About
**************************** */
  .about_me {
      /* background-color: rgb(29, 30, 32); */
      height: 100vh;
  }

  .about {
    /* width: 40em; */
    margin: auto; 
    font-size: 2em;
    color: white;
  }

  .about_text{
    color: white;
  }

/* ****************************
Resume
**************************** */

  #resume{
      text-align: left;
      margin-left: 20px;
  }

  #resume h1{
      margin-bottom: 0;
  }

  #resume header {
    font-size: 1.25em;
    display: flex;
    justify-content: right;
  }
  #resume header span:first-of-type{
    margin-right: 50px;
  }
  #resume div {
      padding-top: 15px; 
  }
  #resume div span {
      color: goldenrod;
      font-size: 1.25em;
  }

  hr {
      width: 100vw;
      margin:0;
  }

  /* ****************************
Screens Smaller than 970px
**************************** */

@media screen and (max-width: 970px){
    .nav__links {
        font-size: 1.25em;
    }
    .about_text{
        font-size: 1.25em;
    }
}

/* ****************************
Screens Smaller than 770px
**************************** */

@media screen and (max-width: 770px){

    .nav__logo {
        font-size: 2em;
      }

    .nav__links {
        font-size: 1em;
    }
    .about_text{
        font-size: 1em;
    }
}

/* ****************************
Screens Smaller than 510px
**************************** */

@media screen and (max-width: 510px){
    #resume h1 {
      font-size: 18px;
    }

    #resume header {
      font-size: 14px;
      justify-content: left;
    }

    .nav__logo {
        font-size: 1em;
      }

    .nav__links {
        font-size: .6em;
        font-weight: bolder;
    }

    .nav a {
        padding: 2px;
    }

    .about_text{
        font-size: .6em;
    }
}